@import "/src/styles/index.scss";
.button-time {
  border: 1px solid $orange-color !important;
  font-size: 12px;
  height: 20px;
  display: flex;
  border-radius: 4px;
  color: $orange-color;
  align-items: center;
  padding: 4px 6px;
  span {
    svg {
    }
  }
  b {
    padding: 0 2px;
    line-height: 20px;
    font-weight: 700;
  }
}
.btn-cooking {
  font: normal normal bold 14px/21px SVN-Merge;
  span {
    margin-bottom: -10px;
  }
}
.button-action {
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.14px;
  color: $red-color;
  text-transform: uppercase;
  padding: 0px 10px;
  height: 40px;
  border: 1px solid #e31837;
  background: transparent;
  border-radius: 6px;
  font: normal normal bold 14px/21px SVN-Merge;
}
.icon-car {
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  img {
    width: 100%;
    height: 100%;
  }
}

// Custom button modal
.ant-modal-footer {
  z-index: 100;
  button {
    font: normal normal bold 14px/21px SVN-Merge;
  }
  .btn-submit {
    height: 55px;
    width: 100%;
    background-color: $green-color-1;
    border-radius: 11px;
    letter-spacing: 0.14px;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal bold 14px/21px SVN-Merge;
    &:hover {
      background-color: $green-color-1;
      color: #ffffff;
    }
  }
  .group-button {
    display: flex;
    margin-top: 15px;
    button {
      width: 50%;
      // border: none;
      height: 34px;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.14px;
      color: $red-color;
      text-transform: uppercase;
      border: none;
      outline: none !important;
      background: transparent;
      font: normal normal bold 14px/21px SVN-Merge;
      span {
        margin-right: 5px;
      }
    }
    .border-center {
      width: 1px;
      height: 30px;
      background-color: #dcdcdc;
    }
  }
}
