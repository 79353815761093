@media only screen and (max-width: 1300px) and (min-width: 993px) {
  .board-wrapper{
    overflow-x: scroll;
    flex-wrap: nowrap;
    .board-columns{
      max-width:24%;
      flex: 0 0 24%;
      margin-right: 12px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .btn-print-order{
    width: auto;
    font-size: 12px !important;
  }
  .button-readyship {
    width: 270px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  html {
    font-size: 60%;
  }
  .board-wrapper{
    overflow-x: scroll;
    flex-wrap: nowrap;
    .board-columns{
      max-width:32%;
      flex: 0 0 32%;
      margin-right: 12px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .btn-print-order{
    width: auto;
    font-size: 10px !important;
  }
}

@media (max-width: 767px) {
  html {
    font-size: 55%;
  }
  .board-wrapper{
    overflow-x: scroll;
    flex-wrap: nowrap;
    .board-columns{
      max-width:100%;
      flex: 0 0 100%;
      margin-right: 12px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  html {
    font-size: 45%;
  }

 
}
