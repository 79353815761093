@import "/src/styles/index.scss";

.order-item {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 6px #9f9f9f29;
  border-radius: 6px;
  margin-bottom: 12px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid transparent;
  &:hover{
    transition: 0.5s;
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
    border: 1px solid $red-color;
  }
  .order-item-box{
    padding: 12px 7px;
    // height: 130px;
    background: black;
    color: white;
    p{
      color: white;
    }
    h1{
      color: white;
    }
    h3{
      color: white;
    }
    h5{
      color: white;
    }
  }
  .oder-icon-arow{
  
  }
  .border-y {
    font-size: 12px;
    border-top: 1px solid #e8e8e8;
    padding: 0.5rem 0;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    .green {
      color: $green;
    }
    .yellow {
      color: $blue;
    }
    .zalo{
      color : #126AF3
    }
  }
  .order-item-moved{
    background-color: $red-color;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 4px 7px;
    span{
      margin-right: 5px
    }
  }
  .order-item-header {
    @include flex-center();
    h3 {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
    }
    h5 {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      color: #e31837;
    }
  }
  .order-item-desc {
    @include flex-center();
    p {
      font-size: 12px;
      letter-spacing: 0px;
      color : #EDEDED
    }
  }
  .order-item-time {
    border-top: 1px solid #e8e8e8;
    padding-top: 9px;
    @include flex-center();
  
    .order-item-desc {
      display: flex;
      align-items: center;
      img {
        padding: 3px;
        margin-right: 5px;
      }
      .order-shop {
        font-size: 24px;
        color: $white-color;
        padding: 3px;
        background-color: $green-color-3;
        margin-right: 5px;
      }
      .btn-order-cancel{
        width: 50px;
        height: 20px;
        background-color: $red-color;
        text-align: center;
        font: normal normal bold 10px/13px Roboto;
        letter-spacing: 0px;
        color: #FFFFFF;
        border-radius: 4px;
      }
     
    }
    .btn-order-confirm{
        border: 1px solid $gray-color !important;
        border-radius: 4px;
        font-size: 10px;
        letter-spacing: 0px;
        color: #414141;
        padding: 7px 10px;
        width: 72px;
        height: 30px;
        font-weight: 800;
      }
  }
  .order-item-voucher {
    border-top: 1px solid #e8e8e8;
    padding-top: 9px;
    @include flex-center();
  }
}

.notResponse{
  border : 2.5px solid red;
}
