@import "/src/styles/index.scss";
.wifi_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.wifi_title{
    font: normal normal bold 14px/21px SVN-Merge;
    font-size: 24px;
    color: #D0343E;
    margin: 20px;
}

.wifi_content{
    font-size: 18px;
    color: #404040;
    line-height: 24px;
    font-weight: 500;
}