.customInput{
    border: 1px solid #818a91;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
}

.customInput:focus{
    box-shadow: 0 0 2px rgb(253, 241, 241) !important;
    background:  #f0f0f0;
    border: 1px solid #ffbd15;

}