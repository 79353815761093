@import "../../styles/variables";
.ant-table-tbody>tr>td{
  background-color: #000;
  color: white;
}

.ant-table{
  background-color: #000;
}
.ant-table-tbody>tr.ant-table-row:hover>td{
  background: #333 !important;
}
.modal-order-history {
    .ant-modal-title {
      padding-top: 2.1rem;
      padding-bottom: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        letter-spacing: 0.21px;
        line-height: 25px;
        font-size: 20px;
        color: $black-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ant-tag-has-color {
          margin-left: 10px;
          font-size: 11px;
          font-weight: normal;
          letter-spacing: 0.12px;
          color: #ffffff;
          height: 22px;
          border-radius: 4px;
        }
      }
  
      h5 {
        font-size: 12px;
        line-height: 14px;
        color: $black-color;
        font-weight: normal;
        padding-top: 5px;
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          height: 16px;
          width: 1px;
          background-color: #dcdcdc;
          margin: 0 7px;
        }
        b {
          font-weight: normal;
          color: $red-color;
        }
      }
    }
    .ant-modal-content {
      height: 750px;
      border-radius: 17px !important;
      padding-bottom: 17px;
    }
    .ant-modal-header {
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 17px 17px 0 0;
      border-bottom: none;
    }
    .ant-modal-close {
      right: -65px;
      width: 46px;
      height: 46px;
      border-radius: 100%;
      background-color: $red-color;
  
      .ant-modal-close-x {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          color: $white-color;
          font-size: 17px;
        }
      }
    }
    .ant-modal-body {
      padding-top: 0;
    }
    .modal-info {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #dcdcdc;
      border-top: 1px solid #dcdcdc;
      padding-top: 9px;
      padding-bottom: 15px;
      .modal-info-left {
        display: flex;
        justify-content: space-between;
        max-width: 49%;
        flex: 0 0 49%;
        border-right: 1px solid #dcdcdc;
        padding-right: 5px;
        .modal-info-left-text {
          display: flex;
          .modal-info-desc {
            padding-left: 10px;
            P {
              line-height: 17px;
              font-size: 14px;
              letter-spacing: 0.14px;
              color: $black-color;
              font-weight: bold;
            }
            .modal-info-time {
              color: $green-color-1;
              line-height: 17px;
            }
            span {
              font-size: 14px;
              letter-spacing: 0.14px;
              color: $black-color;
              line-height: 17px;
            }
          }
        }
        .button-time {
          width: 25%;
        }
      }
      .modal-info-right {
        max-width: 49%;
        flex: 0 0 49%;
        h5 {
          color: $black-color;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.14px;
          // padding-left: 5px;
          font-weight: bold;
        }
        p {
          text-align: left;
          color: $green-color-1;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.14px;
          b {
            text-align: left;
          }
          span {
            text-align: center;
          }
        }
      }
    }
    .modal-remark {
      padding: 12px 0;
      h3 {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.16px;
        color: $black-color;
        line-height: 19px;
        padding-bottom: 5px;
        span {
          padding-left: 11px;
          font-size: 14px;
          color: #0a8d87;
        }
      }
      h5 {
        letter-spacing: 0.14px;
        font-size: 14px;
        line-height: 18px;
        color: #2b2b2b;
      }
    }
    .ant-modal-footer {
      padding-bottom: 5px;
      padding-top: 5px;
      position: absolute;
      width: 100%;
      bottom: 5px;
      .ant-rate{
        color: '#D1D1D1';
      }
    }
  }
.modal-revenue {
  .ant-modal-title {
    padding-top: 2.1rem;
    padding-bottom: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      letter-spacing: 0.21px;
      line-height: 25px;
      font-size: 20px;
      color: $black-color;
      font: normal normal bold 2rem/25px SVN-Merge;
    }
  }
  .ant-modal-content {
    height: 426px;
    border-radius: 17px !important;
    padding-bottom: 17px;
  }
  .ant-modal-header {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 17px 17px 0 0;
    border-bottom: none;
  }
  .ant-modal-close {
    right: -25px;
    top:-18px;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    background-color: $red-color;

    .ant-modal-close-x {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: $white-color;
        font-size: 34px;
      }
    }
  }
  .ant-modal-body {
    padding-top: 0;
  }
  .ant-modal-footer {
    padding-bottom: 5px;
    padding-top: 5px;
    position: absolute;
    width: 100%;
    bottom: 5px;
  }
  .ant-picker{
    border:none;
    .ant-picker-input{
      &:hover{
        border:none;
      }
      input{
        color:#4FA6FF;
        text-decoration: underline;
        font-weight: bolder;
      }
    }
  }
}
.modal-order-status{
  .ant-modal-title {
    padding-top: 2.1rem;
    padding-bottom: 1.2rem;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #d4d4d4;
    h3 {
      letter-spacing: 0.21px;
      line-height: 25px;
      font-size: 20px;
      color: $black-color;
      font: normal normal bold 2rem/25px SVN-Merge;
    }
  }
  .ant-modal-content {
    height: 506px;
    border-radius: 17px !important;
    padding-bottom: 17px;
  }
  .ant-modal-header {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 17px 17px 0 0;
    border-bottom: none;
  }
  .ant-modal-close {
    right: -25px;
    top:-18px;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    background-color: $red-color;

    .ant-modal-close-x {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: $white-color;
        font-size: 34px;
      }
    }
  }
  .ant-modal-body {
    padding-top: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 0;
  }
  .ant-modal-footer {
    padding-bottom: 5px;
    padding-top: 5px;
    position: absolute;
    width: 100%;
    bottom: 5px;
  }
}
.order-status{
  margin-top: 15px;
  overflow-y: scroll;
  max-height: 300px;
  .order-status-title{
    letter-spacing: 0.21px;
    line-height: 25px;
    font-size: 20px;
    color: $black-color;
    font: normal normal bold 2rem/25px SVN-Merge;
    margin-bottom: 5px;
  }
  .order-status-timeline{
    letter-spacing: 0.21px;
    font-weight: 600;
    color: #646464;
    
  }
}

.body_history{
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
}

.txtReasonCancel{
  color: #404040; 
  font-Size: 16px; 
  font-weight: 500;
}

.blockReasonCancel{
  display: block;
  padding-right: 16px;
}

.contentOrderHistory {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.contentOrderHistory::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}